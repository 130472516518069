<template>
  <div class="content">
    <van-list
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="box" v-for="item in recordData" :key="item.id">
        <div class="title">{{item.title}}</div>
        <div class="time">{{item.releaseTime}}提交</div>
        <span>{{item.content}}</span>
      </div>
    </van-list>
    <van-empty v-if="!show" description="暂无记录" />
  </div>
</template>

<script>
import { getFeedbackPage } from '@/api/user'
export default {
  data() {
    return {
      recordData: [],
      show: true,
      finished: false,
      loading: false,
      num: 1
    }
  },
  created() {
    this.getFeedbackPage()
  },
  updated() {
    if (this.recordData.length === 0) {
      this.show = false
    }
  },
  methods: {
    async onLoad() {
      this.num += 1
      const { data } = await getFeedbackPage({
        pageSize: 10,
        pageNum: this.num
      })
      if (data.data.records.length === 0) {
        this.finished = true
      } else {
        this.recordData = [...this.recordData, ...data.data.records]
        this.loading = false
      }
    },
    async getFeedbackPage() {
      const { data } = await getFeedbackPage({
        pageSize: 10,
        pageNum: 1
      })
      console.log(data.data.records)
      this.recordData = data.data.records
    }
  }
}
</script>

<style lang="less" scoped>
.nonesat {
  display: none;
}
#show {
  display: block;
}
.content {
  .van-empty {
    height: 80vh;
  }
  width: 100%;
  padding-top: 15px;
  box-sizing: border-box;
  .box {
    box-shadow: 0 0 4px #cccccc;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: #b5b5b5;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    height: 135px;
    border-radius: 12px;
    padding: 10px;
    box-sizing: border-box;
    .time {
      border: none;
      outline: none;
      font-size: 12px;
      color: #cecece;
      margin: 7px 0;
    }
    .title {
      border: none;
      outline: none;
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
